/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: authentic60;
	src: url('./UI/assets/fonts/AUTHENTICSans-60.otf');
}

@font-face {
	font-family: authentic90;
	src: url('./UI/assets/fonts/AUTHENTICSans-90.otf');
}

@font-face {
	font-family: authentic130;
	src: url('./UI/assets/fonts/AUTHENTICSans-130.otf');
}

/* Text css */
.H1Principal {
	/*font-style: normal;
    font-weight: 600;*/
	font-family: authentic130;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1rem;
}

.H2Secondary {
	/*font-style: normal;
    font-weight: normal;*/
	font-family: authentic90;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1rem;
	color: #007fff;
}

.B1Text {
	/*font-style: normal;
    font-size: 15px;*/
	font-family: authentic90;
	line-height: 22px;
	letter-spacing: 0.1rem;
}

.B2LightText {
	font-family: authentic60;
	letter-spacing: 0.05rem;
}

.HighText {
	font-style: normal;
	font-size: 25px;
	line-height: 24px;
	letter-spacing: 0.1rem;
}

.H3TextWeb {
	font-family: authentic60;
	/*font-style: normal;*/
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.1rem;
}

.B1TextMenu {
	font-size: 15px;
	font-family: authentic90;
	line-height: 18px;
	letter-spacing: 0.1rem;
}

.Abreviaciones {
	font-style: italic;
	font-size: 14px;
	letter-spacing: 0.025rem;
	font-family: Victor Serif Trial;
}

.Abreviaciones_footer {
	font-style: italic;
	font-size: 13px;
	letter-spacing: 0.025rem;
	font-family: Victor Serif Trial;
}

/* edit the image container */

.addImageButton {
	background-color: white !important;
	color: #007fff !important;
	text-decoration: underline !important;
}

.fileUploader {
	height: 230px !important;
	max-width: 320px !important;
}

.fileUploader .fileContainer {
	height: 100% !important;
	max-width: 320px !important;
	border: 1px dashed black !important;
	border-radius: 0px !important;
	box-shadow: 0px 0px 0px 0 rgb(0 0 0) !important;
	margin: 0px !important;
	padding: 0px !important;
}

.fileContainer .deleteImage {
	background: #007fff !important;
}

.fileContainer .uploadPictureContainer {
	padding: 8px !important;
}

@media only screen and (max-width: 767px) {
	.fileContainer .uploadPicturesWrapper {
		position: absolute;
	}

	.fileContainer .uploadPictureContainer {
		width: 100% !important;
		height: 100% !important;
		margin: 0% !important;
	}

	.fileContainer .uploadPictureContainer img.uploadPicture {
		max-height: 200px;
		width: auto !important;
	}
}

@media only screen and (min-width: 768px) {
	.fileUploader {
		height: 100% !important;
		max-width: 590px !important;
	}

	.fileUploader .fileContainer {
		max-width: 590px !important;
		height: 443px !important;
	}

	.fileContainer .uploadPicturesWrapper {
		position: absolute;
	}

	.fileContainer .uploadPictureContainer {
		width: 100% !important;
		height: 100% !important;
		margin: 0% !important;
	}

	.fileContainer .uploadPictureContainer img.uploadPicture {
		max-height: 420px;
		width: 100% !important;
		object-fit: contain;
	}
}

.box_shadowcs {
	box-shadow: 0px 4px 4px 0px #00000040;
}

.box_shadow_buttons {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.filterMain ::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
	border-radius: 9999px;
	background-color: rgba(245, 245, 245, 0.5);
	opacity: 20;
}

.filterMain ::-webkit-scrollbar {
	width: 20px;
	background-color: rgba(245, 245, 245, 0.1);
}

.filterMain ::-webkit-scrollbar-thumb {
	border-radius: 9999px;
	border: 4px solid rgba(0, 0, 0, 0);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background-color: #b6b6b6;
	background-clip: padding-box;
}

/*  tooltipParent and tooltipChildren are used to display elements (tooltip) 
on hover of the toolTipParent */

.tooltipParent:hover .tooltipChildren {
	visibility: visible;
}
